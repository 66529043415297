import { IDealerSystemsUse } from '@rfb/common';
import { Condition, ITable, MessageList, Table as UIKitTable } from '@rfb/ui-kit';
import fp from 'lodash/fp';
import React, { useEffect } from 'react';
import { ITrancheState } from '../../store';
import styles from './assets/styles/index.module.css';

export interface ITrancheTableProps {
  filter: ITrancheState['filter'];
  headers: ITable['headers'];
  rows: ITable['rows'];
  page: number;
  pageCount: number;
  rfInfoErrorList: string[];
  isLoading: boolean;
}

export interface ITrancheTablePropsExternal {
  clientLineId?: string;
  clientLineType?: string;
  dealerSystems?: IDealerSystemsUse;
  multiBrand?: string;
}

export interface ITrancheTableActions {
  actions: {
    getData: Function;
    setFilter: Function;
  };
}

const Table = (props: ITrancheTableProps & ITrancheTablePropsExternal & ITrancheTableActions) => {
  useEffect(() => {
    props.actions.getData({
      ...props.filter,
      clientLineId: fp.defaultTo('', props.clientLineId),
      type: props.clientLineId ? props.clientLineType : props.filter.type,
      multiBrand: props.multiBrand === '1' ? true : props.multiBrand === undefined ? undefined : 0,
    });
  }, [props.filter, props.clientLineId, props.clientLineType, props.actions]);

  const onSortingChange = (sorting: ITable['sorting']): void => {
    return props.actions.setFilter({ sorting });
  };

  return (
    <div className={styles.main}>
      <Condition value={!fp.isEmpty(props.rfInfoErrorList)}>
        <MessageList type="error" messages={props.rfInfoErrorList} />
      </Condition>

      <UIKitTable
        headers={props.headers}
        rows={props.rows}
        sorting={props.filter.sorting}
        page={props.page}
        pageCount={props.pageCount}
        isLoading={props.isLoading}
        onSortingChange={onSortingChange}
        onPageChange={(page: number) => props.actions.setFilter({ page })}
      />
    </div>
  );
};

export default Table;
